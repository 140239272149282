import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SlideshowModule } from 'ng-simple-slideshow';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { IKeyboardLayouts, keyboardLayouts, MAT_KEYBOARD_LAYOUTS, MatKeyboardModule } from '@ngx-material-keyboard/core';
// import { ToastModule } from 'ng2-toastr/ng2-toastr';
import { HttpClientModule } from '@angular/common/http';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ColorPickerModule } from 'ngx-color-picker';
import { ScrollEventModule } from 'ngx-scroll-event';

const customLayouts: IKeyboardLayouts = {
	...keyboardLayouts,
	'Tölles Läyout': {
		'name': 'Awesome layout',
		'keys': [
			[
				['1', '!'],
				['2', '@'],
				['3', '#']
			]
		],
		'lang': ['de-CH']
	}
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		FormsModule,

		SlideshowModule,
		// ToastModule.forRoot(),
		HttpClientModule,
		// Material modules
		MatButtonModule,
		MatKeyboardModule,
		ColorPickerModule,
		ScrollEventModule,
		DateInputsModule
	],
	providers: [
		{ provide: MAT_KEYBOARD_LAYOUTS, useValue: customLayouts }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
