import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "admin",
    loadChildren: "app/component/admin/admin.module#AdminModule"
  },
  {
    path: "queue",
    loadChildren:
      "app/component/queue-card-printing-point/queue-card-printing-point.module#QueueCardPrintingPointModule"
  },
  {
    path: "staff",
    loadChildren: "app/component/staff-display/staff-display.module#StaffDisplayModule"
  },
  {
    path: "monitor",
    loadChildren: "app/component/monitor/monitor.module#MonitorModule"
  },
  { path: "", redirectTo: "/queue", pathMatch: "full" },
  { path: "**", redirectTo: "/queue", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
